.react-datepicker {
    display: flex;
    flex-direction: row;
}

.react-datepicker__input-container input {
    @apply relative flex flex-1 w-full p-input leading-5 text-base text-gray-400 placeholder:text-sm placeholder-gray-400 md:text-sm bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-300 focus:border-transparent
}

.react-datepicker-popper {
    width: fit-content !important;
    @apply z-40 w-72 text-sm bg-white shadow-none px-3 py-2 border border-gray-300 rounded-lg
}

.react-datepicker-left {
    @apply absolute left-0 right-auto top-11 transform-none !important
}

.react-datepicker-right {
    @apply absolute right-0 left-auto top-11 transform-none !important
}

.react-datepicker__portal {
    @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-300 rounded
}

.react-datepicker__month-container {
    @apply flex flex-col mx-4
}

.react-datepicker__month {
    @apply flex flex-col
}

.react-datepicker__current-month {
    @apply ml-2.5 text-base font-medium text-gray-800
}

.react-datepicker__week {
    @apply flex justify-around
}

.react-datepicker__day-names {
    @apply flex justify-around text-gray-400 font-medium text-center text-xs
}

.react-datepicker__day-name {
    @apply w-8 h-8 flex items-center justify-center py-1 rounded-full
}

.react-datepicker__navigation {
    @apply absolute top-2
}

.react-datepicker__navigation--previous {
    @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__navigation--next {
    @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__day {
    @apply  w-8 h-8 flex items-center justify-center py-1 text-sm transition text-gray-700
}

.react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-40 hover:bg-transparent
}

.react-datepicker__day--outside-month {
    @apply text-gray-300
}

.react-datepicker__day--in-range {
    @apply bg-amber-300 m-0 border-none text-black
}

.react-datepicker__day--in-selecting-range {
    @apply bg-amber-300 text-black
}

.react-datepicker__day--selecting-range-start {
    @apply bg-amber-300 rounded-l-full 
}

.react-datepicker__day--selecting-range-end {
    @apply bg-amber-300 rounded-r-full
}

.react-datepicker__day--selected {
    @apply bg-amber-300 text-black
}

.react-datepicker__day--range-start {
    @apply bg-amber-300 rounded-l-full text-black
}

.react-datepicker__day--range-end {
    @apply bg-amber-300 rounded-r-full text-black
}
.react-datepicker__aria-live {
    display: none;
}