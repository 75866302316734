[type='radio'] {
    color: rgb(255, 166, 0);
}

input[type="checkbox"][readonly] {
    pointer-events: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;

}